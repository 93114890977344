
function akkStateChange() {

  for(let i = 1; i <= 100; i++) {

      $( "#heading-"+i ).on( "click", function() {

          var src = $("#akk-icon-"+i).attr('src');
          var newsrc = (src=='/wp-content/uploads/icon_minus_white.svg') ? '/wp-content/uploads/icon_plus_white.svg' : '/wp-content/uploads/icon_minus_white.svg';
          $("#akk-icon-"+i).attr('src', newsrc );
          $(".akk-icon-image").not( "#akk-icon-"+i ).attr('src', '/wp-content/uploads/icon_plus_white.svg' );

          // $( "#akk-icon-"+i ).toggleClass("filter-white");
          // $(".akk-icon-image").not( "#akk-icon-"+i ).removeClass("filter-white");

          $("#heading-"+i).toggleClass('akk-active');
          $(".card-header").not( "#heading-"+i ).removeClass('akk-active');

          });
      }
  }

  export { akkStateChange };


function akkSprungMarker() {


  $('.collapse').on('show.bs.collapse', function(e) {
      var $card = $(this).closest('.card');
      var $open = $($(this).data('parent')).find('.collapse.show');

      var additionalOffset = 0;
      if($card.prevAll().filter($open.closest('.card')).length !== 0)
      {
            additionalOffset =  $open.height();
      }
      $('html,body').animate({
        scrollTop: $card.offset().top - additionalOffset - 175
      }, 500);
    });

}

export { akkSprungMarker };
