// function testimonials() {
//   setTimeout(function () {
//     $(".carousel-inner > div:nth-child(1)").addClass("active");
//   }, 100);
//   return false;
// }
// export { testimonials };


function testimonials() {
  let owlCardsSlider = jQuery(".testimonial-slider-inner");
  //var itemCount = jQuery(owl).find('.stage').length;

  owlCardsSlider.owlCarousel({
    //animateOut: 'fadeOut',
    mouseDrag: false,
    touchDrag: true,
    loop: true,
    dotsEach: true,
    nav: false,
    dots: true,
    //margin: 0,
    //stagePadding: 0,
    autoplay: true,
    smartSpeed: 2000,
    items: 3,
    lazyLoad: true,
    navSpeed: 2000,
    autoplaySpeed: 2000,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 2,
      },
      1200: {
        items: 3,
      },
    },
  });
}

export { testimonials };
