import jQuery from "jquery";
import "@fancyapps/fancybox";
import "bootstrap";
import "animate.css";
import "owl.carousel";
import { WOW } from "wowjs";
import { lazyload } from "./globals/lazyload";
import { gutenbergColumnClasses } from "./globals/gutenbergColumnClasses";
import { toggleNavigation } from "./layout/navigation";
import { langSelectHide } from "./layout/navigation";
import { navCloserOnClick } from "./layout/navigation";
import { langChoose } from "./layout/navigation";
import { toggleSearch } from "./layout/search";
import { slider } from "./partials/slider";
import { accordion } from "./partials/accordion";
import { enableForm } from "./partials/form";
import { testimonials } from "./partials/testimonials";
import { videoPlay } from "./partials/video";
import { scrollTo } from "./partials/scrollTo";
import { akkStateChange } from "./blocks/akkordeon";
import { akkSprungMarker } from "./blocks/akkordeon";
import { metaMenuAdd } from "./blocks/metaMenuAdd";
import { modalVideo } from "./blocks/modalVideo";

jQuery(document).ready((_) => {
  lazyload();
  //gutenbergColumnClasses();
  toggleNavigation();
  toggleSearch();
  accordion();
  enableForm();
  testimonials();
  scrollTo();
  slider();
  akkStateChange();
  akkSprungMarker();
  metaMenuAdd();
  modalVideo();
  langSelectHide();
  langChoose();
  navCloserOnClick();
  videoPlay();
  new WOW().init();
});
