function toggleNavigation() {

  // -- reset navbar-toggle
  var $toggle = $(".navbar-toggler"),
    $navbar = $(".navbar-collapse");

  $toggle.on("click", function () {
    $(this).toggleClass("collapsed");
    $navbar.toggleClass("show");
    $("body").toggleClass("open-nav");
    return false;
  });
}
export { toggleNavigation };

function langSelectHide() {
  $(".navbar-toggler").click(function () {
    $(".nav-lang-selector-container").toggleClass("langSelectDisappear");
    $(".nav-lang-selector-container").toggleClass("langSelectShow");
  });
}
export { langSelectHide };

function langChoose() {
  let trigger = $("#nav-lang-selector");
  let list = $("#nav-lang-selector-list");

  trigger.click(function () {
    trigger.toggleClass("active");
    list.slideToggle(200);
  });

  list.click(function () {
    trigger.click();
  });
}

export { langChoose };

function navCloserOnClick() {
  $(document).ready(function () {
    $(document).click(function () {
      if ($("body").hasClass("open-nav")) {
        $(".navbar-toggler").click();
      }
    });
  });
}

export { navCloserOnClick };
