function videoPlay() {
  $(".site-video")
    .on("play", function () {
      $(this).closest("div").find(".playbutton").hide();
      $(this).closest("div").find(".video-overlay").hide();
      $(this).closest("div").find(".video-text-container").hide();
      
      $('.site-video').not($(this)).get(0).pause();
    })
    .on("pause ended", function () {
      $(this).closest("div").find(".playbutton").show();
      $(this).closest("div").find(".video-overlay").show();
      $(this).closest("div").find(".video-text-container").show();
      console.log("video Stopped");
    })
    .on("playing", function () {
      $(this).closest("div").find(".playbutton").hide();
      $(this).closest("div").find(".video-overlay").hide();
      $(this).closest("div").find(".video-text-container").hide();
      console.log("video plays atm");
    });
}

export { videoPlay };
